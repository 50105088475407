.welcome-hero {
    margin-top:20px;
    padding-top:20px;
    padding-bottom: 40px;
    background: #ffffff;
    /* margin-left:35px;
    margin-right:35px; */
}

/*  */
.welcome-hero h1 {
    font-size: 3rem;
    background-size: 1px 1em;
    display:inline;
    box-shadow: #fff 0px -0.075em inset, rgb(252, 242, 20) 0px -0.5em inset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media (max-width: 767px) {
    .welcome-hero h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 540px) {
    .welcome-hero h1 {
        font-size: 2.0rem;
    }
}

.welcome-hero .hero-image {
    max-width: 435px;
    margin-left: 60px;
}

@media (max-width: 540px) {
    .welcome-hero .hero-image {
        max-width: 335px;
    }
}

.welcome-hero .lead {
    font-family: "FuturaLightC";
    margin-top: 40px;
    margin-bottom: 30px;
    font-size:1.5rem;
}

.welcome-hero .leadButton {
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:30px;
    padding-right:30px;
    margin-bottom: 20px;
}

.welcome-hero .leadButton:hover {
    background: #000;
    color: #FFED00;
}