.api-dictionary {
}

.api-dictionary-content {
  margin-top: 40px;
  margin-bottom: 120px;
}

.api-dictionary-content h2 {
  font-family: 'FuturaMediumC';
  font-size: 2rem;
  line-height: 3.1rem;
}

.api-dictionary-content h3 {
  font-family: 'FuturaLightC';
  font-size: 2rem;
  line-height: 3.1rem;
  margin-top: 10px;
}

.api-dictionary-content h4 {
  font-family: 'FuturaBookC';
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-top: 20px;
}

.api-dictionary-content p {
  padding-left: 20px;
}

.api-dictionary-content table {
  /* margin-left: 20px; */
  margin-bottom: 20px;
}

.dict-item {
  font-family: 'FuturaLightC' !important;
  font-size: 1.2rem !important;
  line-height: 2.1rem !important;
}

.dict-description {
  background-color: #f4f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dict-item hr {
  padding-top: 20px;
}
