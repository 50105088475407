.form-highlights {
    padding-top: 80px;
    padding-bottom: 40px;
    background: rgb(255, 237, 0);
    /* background: rgb(74, 74, 74); */
    /* background: rgba(0,0,0, 0.647); */
    background: #464644;
    color: #fff;
    /* background: #fff; */
}


.form-highlights h2 {
    font-size: 2.3rem;
    background-size: 1px 1em;
    display:inline;
    box-shadow: rgb(255, 237, 20) 0px -1.275em inset, rgb(255, 237, 20) 0px -0.4em inset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #000;
    
    margin-bottom: 40px;
}

.form-highlights .lead {
    font-family: "FuturaLightC";
    margin-top: 40px;
    margin-bottom: 40px;
    font-size:1.5rem;
}

.form-highlights .feature {
    margin-top: 20px;
}

.form-highlights .features {
    /* background: rgb(244, 245, 245); */
    /* background: #fff; */
    /* background: rgb(255, 237, 0); */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px -4px; */
    
    border-radius: 4px;
    padding-top:20px;
    padding-bottom: 20px;
}

.form-highlights .feature h3 {
    padding-left:10px;
    padding-right: 10px;
    color: rgb(255, 237, 20);
    margin-top: 30px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "FuturaDemiC";
    text-align: center;
}

.form-highlights .feature-icon {
    /* background-color: #fff; */
    background: rgb(255, 237, 0);
    height: 112px;
    width: 112px;
    display: block;
    line-height: 7.5rem;
    font-size: 2.25rem;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.form-highlights .feature-icon img {
    display: block;
    margin: auto;
}

.form-highlights .feature-icon .img1 {
    width: 70px;
    padding-top: 35px;
}

.form-highlights .feature-icon .img2 {
    height: 95px;
    padding-top: 20px;
}

.form-highlights .feature-icon .img3 {
    height: 90px;
    padding-top: 25px;
}

.form-highlights .feature-description {
    padding-left:40px;
    padding-right:40px;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.9rem;
    font-family: "FuturaLightC";
}

.form-highlights .call-to-action {
    text-align: center;
    margin-top: 60px;
}

.form-highlights .call-to-action .cta-button {
    /* background: #fff; */
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:30px;
    padding-right:30px;
    margin-bottom: 20px;
}

.form-highlights .call-to-action .cta-button:hover {
    background: #000;
    color: #fff;
    border-color: #000;
}


