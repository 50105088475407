.api-standard {
  margin-bottom: 120px;
}

.api-standard-content {
  margin-top: 40px;
}

.api-standard-content h2 {
  font-family: 'FuturaMediumC';
  font-size: 2rem;
  line-height: 3.1rem;
}

.api-standard-content h3 {
  font-family: 'FuturaLightC';
  font-size: 2rem;
  line-height: 3.1rem;
  margin-top: 10px;
}

.api-standard-content h4 {
  font-family: 'FuturaBookC';
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-top: 20px;
}

.api-standard-content p {
  /* padding-left: 20px; */
}

.api-standard-content table {
  /* margin-left: 20px; */
  margin-bottom: 20px;
}
.api-standard-content
  .table-compact
  .table-wrap
  .relative-table.confluenceTable {
  font-size: 13px;
}
