


.api-list {
    padding-top:60px;
    margin-bottom: 60px;
    background: #fff;
}

.api-list h2 {
    font-size: 2.3rem;
    margin-bottom: 40px;
}

.api-list .lead {
    font-family: "FuturaLightC";
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
    font-size:1.5rem;
}

.api-list hr {
    margin-top: 60px;
    margin-bottom: 10px;
}

.api-list .btn.btn-primary {
    font-size: 1.2rem;
    font-family: "FuturaBookC";
    /* background-color: #eee;
    border-color: #ddd; */
}