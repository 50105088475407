.navigation-bar {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.navigation-bar--row {
  margin-bottom: 20px;
}

/* .navigation-bar--start {
    margin-left:50px;
}
.navigation-bar--end {
    margin-right:40px;
} */

/* .navigation-bar-row2--start {
    margin-left:20px;
}
.navigation-bar-row2--end {
    margin-right:40px;
} */

/* .navigation-bar_logo {
    min-width: 185px;
    min-height: 42px;
    padding-right:20px;
    padding-bottom:20px;
} */

.navigation-bar-logo {
  margin-bottom: 20px;
}

.navigation-bar-logo img {
  width: 260px;
}

@media only screen and (max-width: 600px) {
  .navigation-bar-logo img {
    width: 120px;
  }
}

.navigation-bar_list {
  margin-left: 0px;
  padding-left: 20px;
}

.navigation-bar_list li {
  margin-left: 20px;
  display: inline;
}

.navigation-bar_items a.nav-link {
  font-size: 1.3rem;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0, 0.9);
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 0px;
  padding-right: 40px;
}

.navigation-bar .dropdown-item.active,
.navigation-bar .dropdown-item:active {
  color: #000;
}
