.api-sandbox {
  margin-bottom: 120px;
}

.api-sandbox-content {
  margin-top: 0px;
}

.api-sandbox-content h2 {
  font-family: 'FuturaMediumC';
  font-size: 2rem;
  line-height: 3.1rem;
}

.api-sandbox-content h3 {
  font-family: 'FuturaLightC';
  font-size: 2rem;
  line-height: 3.1rem;
  margin-top: 10px;
}

.api-sandbox-content h4 {
  font-family: 'FuturaBookC';
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-top: 20px;
}

.api-sandbox-content p {
  padding-left: 10px;
  font-size: 1.1rem;
  line-height: 1.8rem;
}

.api-sandbox-content .form-label {
  font-weight: 600;
}
