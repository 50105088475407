.api-endpoint .badge {
    margin-right: 5px;
    font-family: "FuturaBookC";
    font-size: 0.8rem;
    line-height: 1.8rem;
}

.api-endpoint .endpoint-value {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-family: "Source Code Pro",monospace;
    color: #3b4151;
    font-weight: 300!important;
}