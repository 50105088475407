.api-product-highlight-item {
    background: #fff;
    padding: 10px;
    /* border: solid 1px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px -4px;
    color: rgb(0, 0, 0);
    text-overflow: ellipsis;
    padding: 20px;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 40px;
    min-height: 300px;
}

.api-product-highlight-item h3 {
    font-weight:700;
    font-size: 1.7rem;

    font-family: "FuturaBookC";

    background-size: 1px 1em;
    display:inline;
    box-shadow: rgb(255, 237, 20) 0px -1.275em inset, rgb(255, 237, 20) 0px -0.4em inset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.api-product-highlight-item a {
    color: #000;
    text-decoration: none;
}

.api-product-highlight-item .product-image {
    /* max-width:250px; */
    /* margin-bottom: 410px; */
    display: block;

    margin-left: auto;
    margin-right: auto;

}

.api-product-highlight-item .product-description {
    margin-top: 60px;
    height: 320px;
    position: relative;
    overflow: hidden;
    font-size: 1.2rem;
    font-family: "FuturaLightC";
    line-height: 1.6rem;
}


  @media only screen and (min-width: 992px) {
    .api-product-highlight-item .product-description {
        height: 320px;
    }
  }


@media only screen and (min-width: 1187px) {
    .api-product-highlight-item .product-description {
        height: 320px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .api-product-highlight-item .product-description {
        height: 240px;
    }
  }

.api-product-highlight-item .product-description-text {
    margin-top: 20px;
}







.api-product-highlight-item .product-icon {
    max-height: 150px;
}

.api-product-highlight-item .product-footer {
    height: 90px;
    position: absolute;
    bottom: 40px;
    width: calc(100% - 40px);
    border: solid 10px #000;
}


.api-product-highlight-item .product-divider {
    /* padding-left: 20px;
    padding-right: 20px; */
    margin-top: 20px;
    margin-bottom: 20px;
    color: #f4f5f5;
}



.api-product-highlight-item .product-api-count {
    padding-top: 3px;
 font-size: 15px;
 line-height: 18px;
 float:left;

 font-family: "FuturaLightC";
}

.api-product-highlight-item .product-cta {
    float:right;
    text-align: right;

}

.api-product-highlight-item .product-cta-text {
    font-family: "FuturaBookC";
    text-decoration: none;
    font-size: 16px;
    line-height:24px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(0, 0, 0);
    text-transform: uppercase;
}

.api-product-highlight-item .product-cta-text:hover {
    color: rgba(0, 0, 0, 0.847);
}