.confluence-content {
  /* padding: 20px !important; */
  word-wrap: break-word;
  margin-bottom: 120px;
}

.confluence-content + .confluence-content {
  padding-top: 0px !important;
}

.confluence-content p {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.confluence-content h2 + p > a {
  margin-left: 0px !important;
  /* padding-left: 20px !important; */
}

.confluence-content .content {
  font-family: 'FuturaLightC' !important;
  font-size: 1.2rem !important;
  line-height: 2.1rem !important;
}

.confluence-content table {
  border-collapse: collapse !important;
  max-width: 1000px;
}

/* .confluence-content table {
    border-collapse: collapse !important;
    margin-left: 0px !important;
    table-layout: fixed !important;
    width: 100% !important;

} */

.confluence-content table,
.confluence-content th,
.confluence-content td {
  border: 1px solid #ccc !important;
  padding: 10px !important;
}

/* .confluence-content th, .confluence-content td {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
} */

.confluence-content .highlight-grey,
.confluence-content .confluenceTh {
  background: #f4f5f5 !important;
  font-family: 'FuturaBookC' !important;
}

.confluence-content h2 {
  font-family: 'FuturaBookC' !important;
  font-size: 1.6rem !important;
  line-height: 2.3rem !important;
}

.confluence-content table + h4 {
  margin-top: 40px !important;
}
