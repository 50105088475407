
.get-started {
    padding-top:60px;
    padding-bottom: 20px;
    background: #f4f5f5;
}

.get-started h2 {
    font-size: 2.3rem;
    margin-bottom: 40px;
}

.get-started .lead {
    font-family: "FuturaLightC";
    margin-top: 0px;
    margin-bottom: 40px;
    font-size:1.5rem;
}

.get-started .step  {
    
    margin-top: 40px;
    margin-bottom: 80px;
}



.get-started .step img {
    
    max-height: 226px;
    display: block;
    margin: auto;
    padding-right: 80px;
    margin-bottom: 40px;
}

.get-started h3 {
    font-weight:700;
    font-size: 1.7rem;
    
    font-family: "FuturaBookC";

    background-size: 1px 1em;
    display:inline;
    box-shadow: rgb(255, 237, 20) 0px -1.275em inset, rgb(255, 237, 20) 0px -0.4em inset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.get-started .details {
    overflow: hidden;
    font-size: 1.2rem;
    font-family: "FuturaLightC";
    line-height: 1.6rem;
    font-family: "FuturaLightC";
    margin-top: 20px;
    
}

.get-started .details:first {
    margin-bottom: 40px;
}



.get-started  .call-to-action {
    
    text-align: center;
}

.get-started  .call-to-action .cta-button {
    /* background: #fff; */
    font-size: 1.5rem;
    padding-top: 10px;
    
    padding-left:30px;
    padding-right:30px;
    margin-bottom: 20px;
}

.get-started  .call-to-action .cta-button:hover {
    background: #000;
    color: #fff;
    border-color: #000;
}


