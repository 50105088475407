.form-list-item {
    background: #fff;
    padding: 10px;
    /* border: solid 1px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px -4px;
    color: rgb(0, 0, 0);
    text-overflow: ellipsis;
    padding: 20px;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 40px;
    /* height: 500px; */
    /* min-height: 465px; */
}

.form-list-item h3 {
    font-weight:700;
    
    font-size: 1.7rem;
    
    font-family: "FuturaBookC";

    background-size: 1px 1em;
    display:inline;
    box-shadow: rgb(255, 237, 20) 0px -1.275em inset, rgb(255, 237, 20) 0px -0.4em inset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.form-list-item a {
    color: #000;
    text-decoration: none;
}

.form-list-item .product-image {
    /* max-width:250px; */
    /* margin-bottom: 410px; */
    display: block;
    
    margin-left: auto;
    margin-right: auto;
    
}

.form-list-item .product-description {
    /* margin-top: 60px; */
    /* height: 125px; */
    position: relative;
    overflow: hidden;
    font-size: 1.2rem;
    font-family: "FuturaLightC";
    line-height: 1.6rem;
    margin-bottom: 20px;
}

.form-list-item .product-description-text {
    margin-top: 20px;
}







.form-list-item .product-icon {
    max-height: 465px;
    margin-bottom: 20px;
}

.form-list-item .product-footer {
    height: 90px;
    position: absolute;
    bottom: 40px;
    width: calc(100% - 40px);
}


.form-list-item .product-divider {
    /* padding-left: 20px;
    padding-right: 20px; */
    margin-top: 10px;
    margin-bottom: 20px;
    color: #f4f5f5;
}



.form-list-item .product-api-count {
    padding-top: 3px;
 font-size: 15px;
 line-height: 18px;
 float:left;

 font-family: "FuturaLightC";
}

.form-list-item .product-cta {
    float:right;
    text-align: right;

}

.form-list-item .product-cta-text {
    font-family: "FuturaBookC";
    text-decoration: none;
    font-size: 16px;
    line-height:24px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(0, 0, 0);
    text-transform: uppercase;
}

.form-list-item .product-cta-text:hover {
    color: rgba(0, 0, 0, 0.847);
}