/* modal */
#form-modal-container .opengui__modal-container {
    /* display: none; */
    z-index: 4000 !important;
  
    transition: opacity .15s linear,visibility .15s linear;
    will-change: opacity,visibility;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
   
    /* pointer-events: none; */
    visibility: hidden;
    opacity: 0;
    overflow-x:hidden;
    overflow-y:hidden;
  }
  
  #form-modal-container .opengui__modal-container.show {
    visibility: visible !important;
    opacity: 1 !important;
  }
  
  #form-modal-container .opengui__modal-container.show .opengui__modal-dialog {
    transform: translate(0,0);
  }
  
  #form-modal-container .opengui__modal-dialog {
    -ms-transform: translate(0,-25%);
    transform: translate(0,-25%);
    transition: transform .3s ease-out;
    /*
    max-width: 1120px;
    width: calc(100% - 80px);
    height: calc(100vh - 80px);
    */
    pointer-events: all;
  }
  
  #form-modal-container .opengui__modal-dialog--vcenter {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  
  #form-modal-container .opengui__modal-dialog--fullHeight {
    max-width: 1120px;
    width: calc(100% - 80px);
    height: calc(100vh - 80px);
    margin: auto;
  }
  
  @media (max-width: 767px) {
    #form-modal-container .opengui__modal-dialog {
        width: 100%;
        margin: 0;
        height: 100vh;
    }
  }
  
  #form-modal-container .opengui__modal-dialog__content {
    position: relative;
    background-color: #f2f1ef;
    padding-bottom: 40px;
    margin: 0px auto;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
    /* padding-top:10px; */
  }
  
  #form-modal-container .opengui__modal-dialog__content--height-auto {  
    height: auto;  
  }
  
  #form-modal-container .opengui__modal-dialog__content--width-small {  
    width:700px;
  }
  
  @media only screen and (max-width: 767px) {
    #form-modal-container .opengui__modal-dialog__content--width-small {  
      width:100%;
    }
  
    #form-modal-container .opengui__modal-dialog__content--height-auto {  
      height: 100%;
    }  
  
    #form-modal-container .opengui__modal-dialog__content {
        padding-bottom: 0px;
    }
  }
  
  /* header */
  #form-modal-container .opengui__modal-dialog__content-header {
    height: auto;
    padding: 20px 52px;
    border-bottom: 1px solid #e0e0e0;
    background: #fff;
    position: relative;
    text-align: center;
  }
  
  /* */
  
  /* close button */
  #form-modal-container .opengui__modal-dialog__button-close {
    cursor: pointer;
    /*
    -ms-transform: translate(50% -50%);
    transform: translate(50% -50%);
    */
  
    /* transform: translate(15px, -120%); */
  
    margin: 0;
    padding: 0;
    border: 0;
    right: 32px;
    background-color: transparent;
    position: absolute;
    top: 25%;
    font-size: 0;
  }
  
  #form-modal-container .opengui__modal-dialog__button-close:hover {
    opacity: .75;
  }
  
  #form-modal-container .opengui__modal-dialog__button-close img {
    border: 0;
    display: block;
  }
  
  /* title */
  #form-modal-container .opengui__modal-dialog__content-header__title {
    font-size: 32px;
    font-weight: 400;
    font-family: 'FuturaBookC';
    line-height: 38px;
  
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  @media (max-width: 991px) {
    #form-modal-container .opengui__modal-dialog__content-header__title {
      line-height: 36px;
      font-size: 30px;
      margin: 0 auto;
    }
  }
  @media (max-width: 767px) {
    #form-modal-container .opengui__modal-dialog__content-header__title {
      line-height: 24px;
      font-size: 20px;
      max-width: 290px;
      margin: 0 auto;
    }
  }
  
  /* body */
  
  #form-modal-container .opengui__modal-dialog__content-body {
    padding: 30px 30px 40px;
    background: #f2f1ef;
    overflow-x: hidden;
    overflow-y: auto;  
    /* padding: 60px 60px 55px; */
  }
  
  @media (max-width: 767px) {
    #form-modal-container .opengui__modal-dialog__content-body {
      margin-bottom:60px;
    }  
  }
  
  #form-modal-container .opengui__modal-dialog__content-body a {
    color: #000;
    font-family: 'FuturaBookC';
  }
  
  #form-modal-container .opengui__modal-dialog__content-body a:hover {
    color: #000;
  }
  
  #form-modal-container .opengui__modal-dialog__subtitle {
    font-family: 'FuturaBookC';
    line-height: 26px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }
  
  #form-modal-container .opengui__modal-dialog__content-text {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
    padding-left: 35px;
    font-family: 'FuturaBookC';
  }
  
  #form-modal-container .opengui__modal-dialog__content-text.data {
    font-weight: 700;
    text-align:center;
    padding-left:0px;
  }
  
  
  /* list */
  #form-modal-container .opengui__modal-dialog__content-list {
    counter-reset: section;
    list-style-type: none;
    font-family: 'FuturaBookC';
    font-size: 18px;
    line-height: 20px;
    list-style: none;
  }
  #form-modal-container .opengui__modal-dialog__content-list li {
    font-family: 'FuturaBookC';
    font-size: 18px;    
    line-height: 20px;
    padding-left: 35px;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 10px;
  }
  
  #form-modal-container ol.opengui__modal-dialog__content-list > li:before {
    counter-increment: section;
    content: counters(section, ".") ". ";
  }
  
  #form-modal-container .opengui__modal-dialog__content-list li:before {
    left: 0;
    position: absolute;
  }
  /* sublist */
  #form-modal-container .opengui__modal-dialog__content-sublist {
    margin-left:20px;
    margin-top:10px;
  }
  
  /* footer */
  
  #form-modal-container .opengui__modal-dialog-footer {
    height: 20px;
    position: absolute;
    left: 0;
    right: 5%;
    bottom: 40px;
    background: linear-gradient(to top,#f2f1ef 30%,rgba(255,255,255,0));
  }