.api-operation-content {
  margin-top: 40px;
  margin-bottom: 120px;
}

.api-operation-content h2 {
  font-family: 'FuturaMediumC';
  font-size: 2rem;
  line-height: 3.1rem;
}

.api-operation-content h3 {
  font-family: 'FuturaLightC';
  font-size: 2rem;
  line-height: 3.1rem;
  margin-top: 10px;
}

.api-operation-content h4 {
  font-family: 'FuturaBookC';
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-top: 20px;
}

.api-operation-content p {
  padding-left: 0px;
}

.api-operation-content table {
  /* margin-left: 20px; */
  margin-bottom: 20px;
}
