@import "bootstrap/dist/css/bootstrap.min.css";
// Override default variables before the import
$body-bg: #fff;

$font-family-sans-serif: "FuturaBookC";

:root {
  --bs-body-font-family: "FuturaBookC";
  --bs-primary: #ffed00;
}

$theme-colors: (
  "primary": #ffed00,
);

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';

* {
  outline: none;
}

html,
body {
  height: 100%;
}

.btn-primary {
  background-color: #ffed00;
  border-color: #ffed00;
  color: #000;
}

.btn-primary:hover {
  background-color: #d9c900;
  border-color: #d9c900;
  color: #000;
  text-decoration: none;
}

.bg-primary {
  background-color: #ffed00 !important;
  color: #000 !important;
}

.dropdown-item.active {
  background-color: #ffed00 !important;
  color: #000 !important;
}

.dropdown-item:active {
  background-color: #f6f5ee !important;
}

/*
badge bg-primary
*/

//

// html { font-size: 1rem; }

@media (min-width: 1px) {
  html {
    font-size: 0.7rem;
  }
}

@media (min-width: 576px) {
  html {
    font-size: 0.8rem;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 0.85rem;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 0.95rem;
  }
}

@media (min-width: 1350px) {
  html {
    font-size: 1rem;
  }
}

a {
  color: #1990fe;
  text-decoration: none;
}

a:hover {
  color: #7fc1fe;
  // text-decoration: underline;
}

h1,
h2 {
  font-family: "FuturaDemiC";
}

.form-control {
  border-width: 3px;
}

#oapiportal {
  min-height: 100vh;
  position: relative;
}

.portal-content {
  padding-bottom: 84px;
}

.sandbox-modal {
  z-index: 4000 !important;
  background: #f4f5f5;
  position: fixed;
  border-left: 1px #ccc solid;
  padding: 30px;

  top: 0;
  right: 0;
  bottom: 0;

  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;

  -webkit-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
  -moz-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
  box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
  // opacity: 0.75;
}

.sandbox-modal.pin {
  width: 85px;
  height: 53px;
  top: 0;
  right: 0;

  box-shadow: none;
  border: 1px #ccc solid;
  background: #f4f5f5;
}

@media (max-width: 1350px) {
  .sandbox-modal {
    padding: 10px;
  }
}

@media (min-width: 1350px) {
  .sandbox-modal {
    width: 40%;
  }

  .sandbox-modal.pin {
    height: unset;
    bottom: 0;
  }
}

.sandbox-modal.pin {
  padding: 0;
}

.icon-button {
  padding: 5px;
  background: #000;
  color: rgb(255, 237, 0);

  font-size: 15px;
  line-height: 15px;
}

.icon-button:hover {
  background: #111;
}

.close-button {
  z-index: 9999;
  background: #f4f5f5;
  padding: 5px;
  //padding-left:10px;

  position: fixed;

  right: 24px;
  top: 11px;
  /* right:0;
      top:0; */
  font-weight: 800;
  cursor: pointer;
}

// .close-button.closed {
//   right: 0px;
//   top: 0px;
// }

.pin .close-button {
  background: #000;
  color: #ffed00;
}

.pin .close-button .icon-button {
  background: unset;
  color: unset;
}
