
.api-invoke h2 {
    font-family: "FuturaMediumC";
    font-size: 2rem;
    line-height: 3.1rem;
}

.api-invoke h3 {
    font-family: "FuturaLightC";
    font-size: 2rem;
    line-height: 3.1rem;
    margin-top:10px;
}


.api-invoke h4 {
    font-family: "FuturaBookC";
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-top:20px;
}

.api-invoke p {
    padding-left: 0px;
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.api-invoke .form-label {
    font-weight: 600;
}

/* .api-invoke .form-label, .api-invoke .form-control {
    font-size: 1.1rem;
    line-height: 1.8rem;
} */

.api-invoke .list-group-item-action.active {
    color: #000;
}

.api-invoke .invoker-content {
    background: #F4F5F5;
    padding: 10px;
}

.api-invoke .response-headers {
    font-size: 0.8rem;
}
