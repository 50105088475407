


.contact-highlights {
    padding-top:60px;
    margin-bottom: 60px;
    background: #fff;
}

.contact-highlights h2 {
    font-size: 2.3rem;
    margin-bottom: 40px;
}

.contact-highlights .lead {
    font-family: "FuturaLightC";
    margin-top: 0px;
    margin-bottom: 40px;
    font-size:1.5rem;
}