.api-navigation-bar {
    margin-bottom: 10px;
}
.api-navigation-bar .nav-item {
    font-family: "FuturaBookC";
    font-size:1.2rem;
    line-height: 2.2rem;
}

.api-navigation-bar .dropdown-item:active {
    color: #000;
}


.api-navigation-bar .dropdown-item.active {
    color: #000;
}

