.swagger-ui {
  margin-bottom: 120px;
}

.api-swagger {
}

.api-swagger-content {
  margin-top: 0px;
}

.api-swagger-content h2 {
  font-family: 'FuturaMediumC';
  font-size: 2rem;
  line-height: 3.1rem;
}

.api-swagger-content h3 {
  font-family: 'FuturaLightC';
  font-size: 2rem;
  line-height: 3.1rem;
  margin-top: 10px;
}

.api-swagger-content h4 {
  font-family: 'FuturaBookC';
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-top: 20px;
}

.api-swagger-content p {
  /* padding-left: 20px; */
}

.api-swagger-content table {
  /* margin-left: 20px; */
  margin-bottom: 20px;
}
.api-swagger-content
  .table-compact
  .table-wrap
  .relative-table.confluenceTable {
  font-size: 13px;
}
