
.sandbox_readonly_endpoint {
    margin-top:0.15rem;
    font-size:1rem;
    line-height:1rem;
    border: 1px solid #ced4da;
    padding:0.1rem;    
    word-break: break-all;
    margin-bottom:0;
    background: #efefef;
    color: #888;
    
}


.sandbox_user_endpoint {
        
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        min-width:280px;
        max-width:100%;
        width:100%;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.__json-pretty__ {
    font-size: 12px;
    /* overflow: auto; */
    /* max-height: 400px; */
    padding: 5px;
    border: solid 1px #dee2e6;
    background: #eee;
    color: #1B1B1B;
    overflow: auto;
    max-height: 500px;
}

.__json-key__ {
    color: #0551A5
}

.__json-value__ {
    color: #098859;
}

.__json-string__ {
    color:#A31515;
}

.__json-boolean__ {
    color: #3041ff;
}

@media (min-width: 270px) {
    .__json-pretty__ {
        max-width:250px;
    }
  }

  @media (min-width: 360px) {
    .__json-pretty__ {
        max-width: 340px;
    }
  }

  @media (min-width: 480px) {
    .__json-pretty__ {
        max-width: 460px;
    }
  }
  
  @media (min-width: 576px) {
    .__json-pretty__ {
        max-width: 556px;
    }
  }
  @media (min-width: 768px) {
    .__json-pretty__ {
        max-width: 748px;
    }
  }
  @media (min-width: 992px) {
    .__json-pretty__ {
        max-width: 972px;
    }
  }

  @media (min-width: 1349px) {
    .__json-pretty__ {
        max-width: 1329px;
    }
  }

    
