.api-forms-content {
    margin-top: 40px;
}

.api-forms-content h2 {
    font-family: "FuturaMediumC";
    font-size: 2rem;
    line-height: 3.1rem;
    margin-bottom: 30px;
}

.api-forms-content h3 {
    font-family: "FuturaLightC";
    font-size: 2rem;
    line-height: 3.1rem;
    margin-top:10px;
}


.api-forms-content h4{
    font-family: "FuturaBookC";
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-top:20px;
}

/* .api-forms-content p {
    padding-left: 20px;
} */

.api-forms-content .h5 {
    font-family: "FuturaMediumC";
}


.api-forms-content .card-img {
    /* width:80%; */
    opacity:0.75;
    
    /* padding: 10px;
    padding-top:20px; */
    margin: auto;
}

.api-forms-content .card-text {
    padding-left: 0px;
}

.api-forms-content .card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px -4px
}

.api-forms-content .card p {
    margin-bottom: 0px;
}

.api-forms-content .btn .btn-primary .card-link {
    width: 100%;
}

.form-instruction {
    width: 100%;
    margin-bottom: 20px;
}

.form-instruction li {
    margin-bottom: 20px;
    
    font-family: "FuturaMediumC";
}

.form-instruction li p {
    margin-top:10px;
    font-weight: 400;
    font-family: "FuturaBookC";
}

.form-instruction-description {
    padding-bottom: 20px;
}

.api-forms-content .content-2fa {
    margin-bottom: 40px;
}

.api-forms-content .content-2fa p {
    margin-left:0px;
    margin-top: 5px;
    padding-left: 0p;
}