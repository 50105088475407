.form-list {
    padding-top:60px;
    padding-bottom: 20px;
    /* background: #f4f5f5; */
}

.form-list h2 {
    font-size: 2.3rem;
    margin-bottom: 40px;
    
}

.form-list .lead {
    font-family: "FuturaLightC";
    margin-top: 0px;
    margin-bottom: 40px;
    font-size:1.5rem;
}

.form-list .product-box {
    background: #fff;
    padding: 10px;
    /* border: solid 1px; */

    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px -4px;
    color: rgb(0, 0, 0);
    text-overflow: ellipsis;
    padding: 20px;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 40px;
}

.form-list .product-box a {
    color: #000;
    text-decoration: none;
}

.form-list h2 {
    font-size: 2.3rem;
    margin-bottom: 40px;
}




