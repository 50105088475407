
.product-view {
    margin-top:20px;
    padding-top:20px;
    padding-bottom: 40px;
    background: #ffffff;
}

.product-view h1 {
    font-size: 2.7rem;
    margin-bottom: 40px;
}

.product-view .lead {
    font-family: "FuturaLightC";
    margin-top: 0px;
    margin-bottom: 40px;
    font-size:1.5rem;
}

.product-view h2 {
    font-weight:700;
    font-size: 1.3rem;
    margin-bottom: 20px;
    
    font-family: "FuturaBookC";

}

.product-view table {
    border-collapse: collapse;
  }
  
  .product-view  table, .product-view  th, .product-view  td {
    border: 1px solid #ccc;
    padding: 10px;
  }

  .nav-link.active {
      /* background: #eee; */
  }

  .highlight-grey {
      background: #f4f5f5;
      font-family: "FuturaBookC";
  }