.api-procedure {

}

.api-procedure-content {
    margin-top: 40px;
}

.api-procedure-content h2 {
    font-family: "FuturaMediumC";
    font-size: 2rem;
    line-height: 3.1rem;
}

.api-procedure-content h3 {
    font-family: "FuturaLightC";
    font-size: 2rem;
    line-height: 3.1rem;
    margin-top:10px;
}


.api-procedure-content h4 {
    font-family: "FuturaBookC";
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-top:20px;
}

.api-procedure-content p {
    /* padding-left: 20px; */
}

.api-procedure-content table {
    margin-left: 20px;
    margin-bottom: 20px;
}



