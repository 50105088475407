.footer {
    box-sizing: border-box;
    padding-top:40px;
    padding-bottom: 40px;
   background: rgb(0, 0, 0);
   color: #fff;
   height: 144px;

   position: absolute;
   bottom: 0;
   width: 100%;
   

   /* position: absolute;
   bottom: 0; */
   /* position: absolute;
   bottom: 0;
   top: 100%; */
   width: 100%;
   /* transform: translateY(224px); */
   
   /* margin-top: 10vh; */
}

.footer a {
    color: #fff;
}

.footer .copyright {
    text-align: center;
}