/* .api-messages h2 {
    font-family: "FuturaMediumC";
    font-size: 2rem;
    line-height: 3.1rem;
} */

.api-messages .execution {
    margin-bottom: 60px;
}

.api-messages .execution .nav-item {
    padding-right: 20px;
}

.api-messages .execution .nav-link.active {
    color: #000;
}

.api-messages .execution .execution-list {
    width: fit-content;
    padding-inline-start: 0px;
}

.api-messages .badge {
    margin-right: 5px;
    font-family: "FuturaBookC";
    line-height: 1.4rem;
    font-size: 0.9rem;
    width: 180px;
    padding-left: 10px;
}

.api-messages .message-meta {
    font-weight:700;
    width: 160px;
    display: block;
    float:left;
}

@media screen and (max-width: 800px) {
    .api-messages .message-meta {
        float:unset;
    }
}

.api-messages .execution .execution-list li {
    list-style-type: none;
    margin-bottom: 5px;
    padding: 5px;   
}

.api-messages .execution .execution-list li.active {
    background-color: #eeeeee;
    /* padding-left: 10px;
    padding-right: 10px; */
}
