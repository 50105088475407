.api-forms {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 120px;
  /* background: #f4f5f5; */
}

.api-forms h2 {
  font-size: 2.3rem;
  margin-bottom: 40px;
}

.api-forms .lead {
  font-family: 'FuturaLightC';
  margin-top: 0px;
  margin-bottom: 40px;
  font-size: 1.5rem;
}

.api-forms .product-box {
  background: #fff;
  padding: 10px;
  /* border: solid 1px; */

  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 12px -4px;
  color: rgb(0, 0, 0);
  text-overflow: ellipsis;
  padding: 20px;
  border-radius: 4px;
  text-decoration: none;
  margin-bottom: 40px;
}

.api-forms .product-box a {
  color: #000;
  text-decoration: none;
}

.api-forms h2 {
  font-size: 2.3rem;
  margin-bottom: 40px;
}
